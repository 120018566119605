import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./slices/globalSlice";
import loginReducer from "./slices/loginSlice";
import dashboardReducer from "./slices/dashboardSlice";
import chmActivityReducer from "./slices/chmActivitySlice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    login: loginReducer,
    dashboard: dashboardReducer,
    chmActivity: chmActivityReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
