import {toast} from "react-toastify";
import {
  handleCHMCropsDataSlice,
  handleCHMDashboardDataSlice,
  handleCHMDashboardViewMapDataSlice,
  handleCHMExcelDownloadDataSlice,
  handleCHMExcelUploadDetailsDataSlice,
  handleCHMExcelUploadStatusDataSlice,
  handleCHMGetDistrictsDataSlice,
  handleCHMGetImagesOneDataSlice,
  handleCHMGetImagesThreeDataSlice,
  handleCHMGetImagesTwoDataSlice,
  handleCHMGetLevel4DataSlice,
  handleCHMGetLevel5DataSlice,
  handleCHMGetStatesDataSlice,
  handleCHMGisChartDataSlice,
  handleCHMInfoByIdDataSlice,
  handleCHMSeasonsDataSlice,
  handleCHMSurveyorDataSlice,
  handleCHMVillagesDataSlice,
  hasError,
  startLoadingCHM,
  stopLoadingCHM,
} from "../redux/slices/chmActivitySlice";

import {method, paths} from "../services/ServiceConstants";
import ServicesManagerAPICall from "../services/ServicesManagerAPICall";

export const getStatesDataAPICall = () => {
  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_STATES_DATA,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMGetStatesDataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getDistrictsDataAPICall = (sid: number) => {
  const body: Object = {
    sid: sid,
  };
  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_DISTRICTS_DATA,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMGetDistrictsDataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getLevel4DataAPICall = (sid: number, did: number) => {
  const body: Object = {
    sid: sid,
    did: did,
  };
  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_LEVEL4_DATA,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMGetLevel4DataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getLevel5DataAPICall = (
  sid: number,
  did: number,
  level4code: number
) => {
  const body: Object = {
    sid: sid,
    did: did,
    level4code: level4code,
  };
  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_LEVEL5_DATA,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMGetLevel5DataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getAllVillagesDataAPICall = (
  sid: number,
  did: number,
  level4code : number,
  level5code?: number
) => {
  const body: any = {
    sid: sid,
    did: did,
    level4code : level4code ?? null,
    level5code: level5code ?? null,
  };

  if (!level4code) delete body.level4code;
  if (!level5code) delete body.level5code;

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_VILLAGES_DATA,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMVillagesDataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getAllSeasonsDataAPICall = () => {
  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_SEASON_DATA,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMSeasonsDataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getCropBySearchAPICall = (cropName: string) => {
  const body: Object = {
    cropName: cropName,
  };

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_CROPS_DATA,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMCropsDataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getCHMInfoAPICall = (
  limit: number,
  offset: number,
  state: number | null | string,
  district: number | null | string,
  level4: number | null | string,
  level5: number | null | string,
  village: number | null | string,
  season: number | null | string,
  cropName: number | null | string,
  search: number | null | string | any,
  fromDate: string | undefined,
  toDate: string | undefined,
  year: number | null | string
) => {
  const body: any = {
    limit: limit,
    offset: offset,
    stateId: state,
    districtId: district,
    level4Code: level4,
    level5Code: level5,
    villageId: village,
    seasonId: season,
    cropId: cropName,
    mobileNumber: search?.split("-")[0].trim(),
    fromDate: fromDate,
    toDate: toDate,
    year: year,
  };

  if (!state) delete body.stateId;
  if (!district) delete body.districtId;
  if (!level4) delete body.level4Code;
  if (!level5) delete body.level5Code;
  if (!village) delete body.villageId;
  if (!season) delete body.seasonId;
  if (!cropName) delete body.cropId;
  if (!search) delete body.mobileNumber;
  if (!fromDate) delete body.fromDate;
  if (!toDate) delete body.toDate;
  if (!year) delete body.year;

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_GET_CHM_INFO,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMDashboardDataSlice(response));
        } else {
          dispatch(handleCHMDashboardDataSlice(response));
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getCHMInfoByIdAPICall = (chmId: string, cropStageId: number) => {
  const body: Object = {
    chmId: chmId,
    cropStage: cropStageId,
  };

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_GET_CHM_INFO_BY_ID,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMInfoByIdDataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getCHMImages = (
  chmId: string,
  cropStage: number,
  imagePath: string,
  imageType: string
) => {
  const body: Object = {
    chmId: chmId,
    cropStage: cropStage,
    imagePath: imagePath,
  };

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_IMAGES,
        body,
        true
      ).then(async (response) => {
        if (imageType === "1") {
          dispatch(handleCHMGetImagesOneDataSlice(response));
          dispatch(handleCHMGetImagesTwoDataSlice({}));
          dispatch(handleCHMGetImagesThreeDataSlice({}));
        } else if (imageType === "2") {
          dispatch(handleCHMGetImagesOneDataSlice({}));
          dispatch(handleCHMGetImagesTwoDataSlice(response));
          dispatch(handleCHMGetImagesThreeDataSlice({}));
        } else if (imageType === "3") {
          dispatch(handleCHMGetImagesOneDataSlice({}));
          dispatch(handleCHMGetImagesTwoDataSlice({}));
          dispatch(handleCHMGetImagesThreeDataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getCHMViewMapData = (
  state: number | null | string,
  district: number | null | string,
  level4: number | null | string,
  level5: number | null | string,
  village: number | null | string,
  season: number | null | string,
  cropName: number | null | string,
  search: number | null | string,
  fromDate: string | undefined,
  toDate: string | undefined,
  year: number | null | string
) => {
  const body: any = {
    stateId: state,
    districtId: district,
    level4Code: level4,
    level5Code: level5,
    villageId: village,
    seasonId: season,
    cropId: cropName,
    mobileNumber: search,
    fromDate: fromDate,
    toDate: toDate,
    year: year,
  };
  if (!state) delete body.stateId;
  if (!district) delete body.districtId;
  if (!level4) delete body.level4Code;
  if (!level5) delete body.level5Code;
  if (!village) delete body.villageId;
  if (!season) delete body.seasonId;
  if (!cropName) delete body.cropId;
  if (!search) delete body.mobileNumber;
  if (!fromDate) delete body.fromDate;
  if (!toDate) delete body.toDate;
  if (!year) delete body.year;

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_VIEWMAP_POLYGON_VALUES,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMDashboardViewMapDataSlice(response));
        } else {
          dispatch(handleCHMDashboardViewMapDataSlice(response));
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const gisComputeIndex = (applicationId: string) => {
  const body: Object = {
    Application_ID: applicationId,
  };
  return async (dispatch: any) => {
    try {
      await ServicesManagerAPICall.request(
        method.POST,
        paths.GIS_COMPUTEINDEX,
        body,
          true,
          false,
          true,
      ).then(async (response) => {});
    } catch (error) {}
  };
};

export const gisCHMChartData = (applicationId: string) => {
  const body: Object = {
    Application_ID: applicationId,
  };
  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.POST,
        paths.GIS_CHM_CHART,
        body,
          true,
          false,
          true,
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMGisChartDataSlice(response));
        } else {
          dispatch(handleCHMGisChartDataSlice(response));
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getCHMExcelData = (
  state: number | null | string,
  district: number | null | string,
  level4: number | null | string,
  level5: number | null | string,
  village: number | null | string,
  season: number | null | string,
  cropName: number | null | string,
  search: number | null | string,
  fromDate: String | undefined,
  toDate: String | undefined,
  year: number | null | string
) => {
  const body: any = {
    stateId: state,
    districtId: district,
    level4Code: level4,
    level5Code: level5,
    villageId: village,
    seasonId: season,
    cropId: cropName,
    mobileNumber: search,
    fromDate: fromDate,
    toDate: toDate,
    year: year,
  };

  if (!state) delete body.stateId;
  if (!district) delete body.districtId;
  if (!level4) delete body.level4Code;
  if (!level5) delete body.level5Code;
  if (!village) delete body.villageId;
  if (!season) delete body.seasonId;
  if (!cropName) delete body.cropId;
  if (!search) delete body.mobileNumber;
  if (!fromDate) delete body.fromDate;
  if (!toDate) delete body.toDate;
  if (!year) delete body.year;

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_EXCEL_DOWNLOAD_DATA,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMExcelDownloadDataSlice(response));
          dispatch(stopLoadingCHM());
        } else {
          dispatch(stopLoadingCHM());
          toast.error(`${response.statusMessage}`);
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const uploadCHMExcelSheet = (userName: String, file: any) => {
  var formData = new FormData();
  formData.append("file", file);
  const multipart = true;

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.POST,
        paths.CHM_EXCEL_SHEET_UPLOAD + "?userName=" + userName,
        formData,
        true,
        multipart
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMExcelUploadDetailsDataSlice(response));
          toast.success(`${response.statusMessage}`);
        } else if (response.statusCode === 0) {
          dispatch(handleCHMExcelUploadDetailsDataSlice(response));
          toast.error(`${response.statusMessage}`);
        } else {
          const data = {
            statusMessage: "Upload Valid Excel",
          };

          dispatch(handleCHMExcelUploadDetailsDataSlice(data));
        }
        dispatch(handleCHMExcelUploadStatusDataSlice(2));
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getCHMSurveyorData = (number: String) => {
  const body: Object = {
    mobileNumber: number,
  };
  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_SURVEYOR_DATA,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          dispatch(handleCHMSurveyorDataSlice(response));
        } else {
          toast.error(response.statusMessage);
          dispatch(stopLoadingCHM());
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const CHMSurveyorAssigne = (
  state: number,
  district: number,
  level4Code: number,
  villageId: number,
  surveyorNunmber: string[]
) => {
  const body: Object = {
    stateId: state,
    districtId: district,
    level4Code: level4Code,
    villageId: villageId,
    surveyorMobileNo: surveyorNunmber,
  };
  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.PUT,
        paths.CHM_ASSIGNE_SURVEYER,
        JSON.stringify(body),
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          toast.success("Survey Assigned Successfully");
          dispatch(stopLoadingCHM());
        } else {
          dispatch(stopLoadingCHM());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const CHMDownloadErrorLogs = (fileId: number) => {
  const body: Object = {
    fileId: fileId,
  };

  return async (dispatch: any) => {
    dispatch(startLoadingCHM());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.CHM_ERORLOGS,
        body,
        true
      ).then(async (response) => {
        if (response.statusCode === 1) {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `${Date.now()}.xlsx`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          toast.success("Error Logs Downloaded Successfully");
        } else {
          toast.success(`${response.statusMessage}`);
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};
