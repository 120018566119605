import { SelectOptionType } from "../models/GlobalModels";

export const Constants = {
  APP_NAME: "Kshema CCE",

  // IS_USER_LOGED_IN: 'isUserLogedIn',
  // AUTHENTICATION_DATUM: 'authenticationDatum',
  // USER_MOBILE_NUMBER: 'userMobileNumber',
};

export const DEFAULT_OPTION: SelectOptionType = { label: "Select", value: null };

export const SIDE_MENU_STATUS = {
  OPEN: 1,
  CLOSE: 2,
  OPPOSITE: 3,
};

export const LIST_VIEW_ITEM = {
  LIST_ITEMS: "list-items",
  VIEW_ITEM: "view-item",
};

export const STORAGE_KEY = {
  AUTH_DATA: "chm-app-auth",
  ITUS: "chm-app-itus",
  SSOT: "fa-sso-token",
};

export const API_RESPONSE_CODE = {
  FAIL: "1",
  SUCCESS: "0",
};

export const LOGIN_STATUS = {
  INITIATED: "initiated",
  AUTHENTICATED: "authenticated",
  NO_AUTHENTICATED: "no-authenticated",
};
