import { Spinner } from "reactstrap";

interface ICenterSpinnerProps {
  showLoader: boolean;
  className?: string;
}

const CenterSpinner: React.FunctionComponent<ICenterSpinnerProps> = ({
  className,
  showLoader,
}) => {
  return (
    <>
      {showLoader && (
        <div
          className={`spinner-element ${
            className ? className : "spinner-center"
          }`}
        >
          <Spinner>Loading...</Spinner>
        </div>
      )}
    </>
  );
};

export default CenterSpinner;
