import { useEffect, useState } from "react";
import {
  CHMFilterType,
  LocationDataType,
} from "../../../models/CHMActivitiesModels";
import { Col, Row } from "reactstrap";
import { DEFAULT_OPTION } from "../../../utils/Constant";
import SelectBox from "../../../components/SelectBox";

import KDatePicker from "../../../components/KDatePicker";
import moment from "moment";
import {
  getDistrictsDataAPICall,
  getStatesDataAPICall,
  getLevel4DataAPICall,
  getLevel5DataAPICall,
  getAllVillagesDataAPICall,
  getAllSeasonsDataAPICall,
  getCropBySearchAPICall,
  getCHMSurveyorData,
} from "../../../api/chmActivityAPI";
import { useDispatch, useSelector } from "react-redux";
import { FormUtils } from "../../../utils/FormUtils";

interface CHMFilterProps {
  filterRawData?: LocationDataType[];
  onApplyFilters: (CHMFilter: CHMFilterType) => void;
}

export const ViewTaskFilter = ({
  onApplyFilters,
  filterRawData,
}: CHMFilterProps) => {
  const [chmFilter, setCHMFilter] = useState<CHMFilterType>({
    state: DEFAULT_OPTION,
    district: DEFAULT_OPTION,
    level4: DEFAULT_OPTION,
    level5: DEFAULT_OPTION,
    village: DEFAULT_OPTION,
    season: DEFAULT_OPTION,
    cropName: DEFAULT_OPTION,
    search: DEFAULT_OPTION,
    fromDate: undefined,
    toDate: undefined,
    year: DEFAULT_OPTION,
  });

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getCHMSurveyorData(""));
    dispatch(getStatesDataAPICall());
    dispatch(getAllSeasonsDataAPICall());
    dispatch(getCropBySearchAPICall(""));
  }, [dispatch]);

  const onResetFilter = () => {
    const resetFilter = {
      state: DEFAULT_OPTION,
      district: DEFAULT_OPTION,
      level4: DEFAULT_OPTION,
      level5: DEFAULT_OPTION,
      village: DEFAULT_OPTION,
      season: DEFAULT_OPTION,
      cropName: DEFAULT_OPTION,
      search: DEFAULT_OPTION,
      fromDate: undefined,
      toDate: undefined,
      year: DEFAULT_OPTION,
    };
    onApplyFilters(resetFilter);
    setCHMFilter(resetFilter);
  };

  const {
    storeCHMGetStatesData,
    storeCHMGetDistrictsData,
    storeCHMGetLevel4Data,
    storeCHMGetLevel5Data,
    storeCHMGetVillagesData,
    storeCHMGetSeasonsData,
    storeCHMGetCropsData,
    storeCHMSurveyorData,
  } = useSelector((state: any) => state.chmActivity);

  const yearsList: any = [
    {
      label: `${new Date().getFullYear() }-${new Date().getFullYear() +1}`,
      value: `${new Date().getFullYear() }-${new Date().getFullYear() +1}`,
    },
    {
      label: `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`,
      value: `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`,
    },
    {
      label: `${new Date().getFullYear() - 2}-${new Date().getFullYear() - 1}`,
      value: `${new Date().getFullYear() - 2}-${new Date().getFullYear() - 1}`,
    },
    
  ];

  const surveyorsData = FormUtils.convertSurveyorDataList(
    storeCHMSurveyorData.data ?? []
  );

  const statesData = FormUtils.convertDataList(
    storeCHMGetStatesData.data ?? []
  );

  const districtsData = FormUtils.convertDataList(
    storeCHMGetDistrictsData.data ?? []
  );

  const level4Data = FormUtils.convertDataList(
    storeCHMGetLevel4Data.data ?? []
  );

  const level5Data = FormUtils.convertDataList(
    storeCHMGetLevel5Data.data ?? []
  );

  const villagesData = FormUtils.convertDataList(
    storeCHMGetVillagesData.data ?? []
  );

  const seasonsData = FormUtils.convertDataList(
    storeCHMGetSeasonsData.data ?? []
  );

  const cropsData = FormUtils.convertDataList(storeCHMGetCropsData.data ?? []);

  const onchangeDropdown = (label: String, event: any) => {
    switch (label) {
      case "State":
        dispatch(getDistrictsDataAPICall(event.value));
        break;
      case "District":
        if (typeof chmFilter.state.value === "number") {
          dispatch(getLevel4DataAPICall(chmFilter.state.value, event.value));
        }

        break;
      case "level4":
        if (
          typeof chmFilter.state.value === "number" &&
          typeof chmFilter.district.value === "number"
        ) {
          dispatch(
            getLevel5DataAPICall(
              chmFilter.state.value,
              chmFilter.district.value,
              event.value
            )
          );

          dispatch(
            getAllVillagesDataAPICall(
              chmFilter.state.value,
              chmFilter.district.value,
              event.value
            )
          );
        }
        break;
      case "level5":
        if (
          typeof chmFilter.state.value === "number" &&
          typeof chmFilter.district.value === "number" &&
          typeof chmFilter.level4.value === "number"
        ) {
          dispatch(
            getAllVillagesDataAPICall(
              chmFilter.state.value,
              chmFilter.district.value,
              chmFilter.level4.value,
              event.value
            )
          );
        }

        break;
      default:
        dispatch(getDistrictsDataAPICall(event.value));
        break;
    }
  };

  return (
    <div className="border rounded ps-3 pt-2 pe-3 pb-3 mb-3">
      <Row>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            label="State"
            options={statesData}
            className="css-select-bg"
            value={chmFilter.state}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  state: event,
                  district: DEFAULT_OPTION,
                  level4: DEFAULT_OPTION,
                  level5: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });

                onchangeDropdown("State", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  state: DEFAULT_OPTION,
                  district: DEFAULT_OPTION,
                  level4: DEFAULT_OPTION,
                  level5: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <SelectBox
            disabled={chmFilter.state.label === "Select"}
            label="District"
            value={chmFilter.district}
            options={districtsData}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  district: event,
                  level4: DEFAULT_OPTION,
                  level5: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });

                onchangeDropdown("District", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  district: DEFAULT_OPTION,
                  level4: DEFAULT_OPTION,
                  level5: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            disabled={chmFilter.district.label === "Select"}
            label="Tehsil"
            options={level4Data}
            className="css-select-bg"
            value={chmFilter.level4}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  level4: event,
                  level5: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
                onchangeDropdown("level4", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  level4: DEFAULT_OPTION,
                  level5: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <SelectBox
            disabled={chmFilter.level4.label === "Select"}
            label="Gram Panchayat"
            options={level5Data}
            className="css-select-bg"
            value={chmFilter.level5}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  level5: event,
                  village: DEFAULT_OPTION,
                });
                onchangeDropdown("level5", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  level5: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            disabled={chmFilter.level4.label === "Select"}
            label="Village"
            options={villagesData}
            className="css-select-bg"
            value={chmFilter.village}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  village: event,
                });
              } else {
                setCHMFilter({
                  ...chmFilter,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            label="Season"
            options={seasonsData}
            className="css-select-bg"
            value={chmFilter.season}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  season: event,
                });
              } else {
                setCHMFilter({
                  ...chmFilter,
                  season: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <SelectBox
            label="Year"
            value={chmFilter.year}
            options={yearsList}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  year: event,
                });
              } else {
                setCHMFilter({
                  ...chmFilter,
                  year: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={2}>
          <KDatePicker
            label="From Date"
            value={chmFilter.fromDate}
            minDate={moment().subtract(1, "years").toDate()}
            maxDate={new Date()}
            onChange={(date) => {
              setCHMFilter({
                ...chmFilter,
                fromDate: date,
                toDate: undefined,
              });
            }}
          />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <KDatePicker
            disabled={chmFilter.fromDate === undefined}
            label="Date To"
            minDate={chmFilter.fromDate}
            maxDate={new Date()}
            value={chmFilter.toDate}
            onChange={(date) => {
              setCHMFilter({
                ...chmFilter,
                toDate: date,
              });
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            label="Crop Name"
            options={cropsData}
            className="css-select-bg"
            value={chmFilter.cropName}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  cropName: event,
                });

                // onchangeDropdown("Crop Name", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  cropName: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        {/* <Col xs={8} md={6}>
          <SearchBox
            label="Search"
            placeHolder="Search with Surveyor Mobile No."
            value={chmFilter.search}
            onChange={(e: any) => {
              setCHMFilter({
                ...chmFilter,
                search: e.target.value,
              });
            }}
          />
        </Col> */}

        <Col  xs={12} md={6} lg={3}>
          <SelectBox
            label="Search"
            options={surveyorsData}
            className="css-select-bg"
            value={chmFilter.search}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  search: event,
                });
              } else {
                setCHMFilter({
                  ...chmFilter,
                  search: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>

        <Col xs={6} md={3} className="d-flex align-items-center mb-2">
          <button style={{marginLeft : 0}}
            className="button"
            onClick={() => {
              onApplyFilters(chmFilter);
            }}
          >
            {" "}
            Apply Filters{" "}
          </button>
          <button
            className="button"
            onClick={() => {
              onResetFilter();
            }}
          >
            {" "}
            Reset{" "}
          </button>
        </Col>
      </Row>
    </div>
  );
};
