import Label from "../../../components/Label";
import CHMActivityMap from "./CHMActivityMap";
import { Col, Modal, ModalBody, Row, Table } from "reactstrap";
import Charts from "../../../components/ChartComponents";
import { useEffect, useState } from "react";
import { CHMActivitiesFormDetails } from "./CHMActivitiesFormDetails";
import { useDispatch, useSelector } from "react-redux";
import { getCHMImages, gisCHMChartData } from "../../../api/chmActivityAPI";
import "./CHMActivities.css";

const CHMFormdetails = () => {
  const [modal, setModal] = useState(false);

  const dispatch: any = useDispatch();
  const {
    isLoading,
    storeCHMDIdData,
    storeCHMImageOneData,
    storeCHMImageTwoData,
    storeCHMImageThreeData,
    storeCHMChartData,
  } = useSelector((state: any) => state.chmActivity);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (storeCHMDIdData.statusCode === 1) {
      dispatch(gisCHMChartData(storeCHMDIdData?.data[0]?.appliationId));
    }
  }, [storeCHMDIdData.statusCode === 1]);

  const showImages = async (type: string) => {
    setModal(true);

    if (type === "3") {
      await dispatch(
        getCHMImages(
          storeCHMDIdData.data[0]?.chmId,
          storeCHMDIdData.data[0]?.cropStage,
          storeCHMDIdData.data[0]?.chmId +
            "-" +
            storeCHMDIdData.data[0]?.cropStage +
            ".jpg",
          type
        )
      );
    } else {
      await dispatch(
        getCHMImages(
          storeCHMDIdData.data[0]?.chmId,
          storeCHMDIdData.data[0]?.cropStage,
          storeCHMDIdData.data[0]?.chmId +
            "-" +
            storeCHMDIdData.data[0]?.cropStage +
            "_" +
            type +
            ".jpg",
          type
        )
      );
    }
  };

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  return (
    <div>
      <Label name="CHM Form Details" arrow={true} />
      <div style={{ marginBottom: "15px" }}>
        {storeCHMDIdData.data && (
          <CHMActivitiesFormDetails
            key={`CHMActivitiesFormDetails`}
            storeCHMDIdData={storeCHMDIdData}
          />
        )}
      </div>
      <Label name="GPS Details" />
      <Row style={{ marginBottom: 10 }}>
        <Col xs={6}>
          <Row style={{ marginBottom: 5 }}>
            <Col xs={6} className="cord-header">
              SWC Latitude
            </Col>
            <Col xs={6} className="cord-header">
              SWC Longitude
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: "#F4F6F8",
              paddingTop: 10,
              paddingBottom: 10,
              marginBottom: 5,
            }}
          >
            <Col xs={6} className="cord-value">
              N/A
            </Col>
            <Col xs={6} className="cord-value">
              N/A
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row style={{ marginBottom: 5 }}>
            <Col xs={6} className="cord-header">
              Submitted Latitude
            </Col>
            <Col xs={6} className="cord-header">
              Submitted Longitude
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: "#F4F6F8",
              paddingTop: 10,
              paddingBottom: 10,
              marginBottom: 5,
            }}
          >
            {storeCHMDIdData?.data ? (
              <>
                <Col xs={6} className="cord-value">
                  {storeCHMDIdData?.data[0]?.lat !== null
                    ? storeCHMDIdData?.data[0]?.lat
                    : "N/A"}
                </Col>
                <Col xs={6} className="cord-value">
                  {storeCHMDIdData?.data[0]?.lan !== null
                    ? storeCHMDIdData?.data[0]?.lan
                    : "N/A"}
                </Col>
              </>
            ) : (
              <>
                <Col xs={6} className="cord-value">
                  N/A
                </Col>
                <Col xs={6} className="cord-value">
                  N/A
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      {storeCHMDIdData?.statusCode === 1 && (
        <CHMActivityMap storeCHMDIdData={storeCHMDIdData} />
      )}

      <br />
      <Label name="GIS Integration" />
      {storeCHMDIdData?.statusCode === 1 && (
        <Row style={{ marginBottom: 10 }}>
          <Col xs={6}>
            <Row style={{ marginBottom: 5 }}>
              <Col xs={6} className="cord-header">
                Crop Harvested
              </Col>
              <Col xs={6} className="cord-header">
                Insured Crop is existing or not
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#F4F6F8",
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 5,
              }}
            >
              <Col xs={6} className="cord-value">
                N/A
              </Col>
              <Col xs={6} className="cord-value">
                N/A
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row style={{ marginBottom: 5 }}>
              <Col xs={6} className="cord-header">
                Crop Name
              </Col>
              <Col xs={6} className="cord-header">
                Insured Vs Sown Crop
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#F4F6F8",
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 5,
              }}
            >
              <Col xs={6} className="cord-value">
                {storeCHMDIdData?.data[0]?.cropNameAsPerApplication ?? "N/A"}
              </Col>
              <Col xs={6} className="cord-value">
                {storeCHMDIdData?.data[0]?.cropNameAsPerField ?? "N/A"}
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {storeCHMChartData?.statusCode === 1 && (
        <Charts storeCHMChartData={storeCHMChartData} />
      )}

      {storeCHMChartData?.statusCode === 0 && (
        <p style={{ fontWeight: 600, color: "red", textAlign: "start" }}>
          {" "}
          *Data Not Found For Charts{" "}
        </p>
      )}

      <Label name="Attachment Details" />

      <Row style={{ marginBottom: 10 }}>
        <Col xs={12}>
          <Row style={{ marginBottom: 5 }}>
            <Col xs={6} className="cord-header" style={{ textAlign: "start" }}>
              Title
            </Col>
            <Col xs={6} className="cord-header">
              Action
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row
            style={{
              backgroundColor: "#F4F6F8",
              paddingTop: 10,
              paddingBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Col xs={6} className="cord-value" style={{ textAlign: "start" }}>
              Cut Plot with All People Standing
            </Col>
            <Col xs={6} className="cord-value">
              <button
                className="button"
                onClick={() => {
                  showImages("1");
                }}
              >
                View
              </button>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row
            style={{
              backgroundColor: "#F4F6F8",
              paddingTop: 10,
              paddingBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Col xs={6} className="cord-value" style={{ textAlign: "start" }}>
              Cut Plot with All People Standing
            </Col>
            <Col xs={6} className="cord-value">
              <button
                className="button"
                onClick={() => {
                  showImages("2");
                }}
              >
                View
              </button>
            </Col>
          </Row>
        </Col>

        {storeCHMDIdData?.statusCode === 1 &&
          storeCHMDIdData?.data[0].damaged && (
            <Col xs={12}>
              <Row
                style={{
                  backgroundColor: "#F4F6F8",
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Col
                  xs={6}
                  className="cord-value"
                  style={{ textAlign: "start" }}
                >
                  Damaged Crop Image
                </Col>
                <Col xs={6} className="cord-value">
                  <button
                    className="button"
                    onClick={() => {
                      showImages("3");
                    }}
                  >
                    View
                  </button>
                </Col>
              </Row>
            </Col>
          )}
      </Row>
      {modal && isLoading === false && (
        <Modal
          isOpen={modal}
          toggle={() => setModal(!modal)}
          centered
          size="xl"
        >
          <ModalBody style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: isMobile() ? "280px" : '400px',
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <img
                  src={require("../../../assets/img/cross.svg").default}
                  style={{
                    height: "30px",
                    width: "30px",
                    alignSelf: "flex-end",
                    backgroundColor: "white",
                    borderRadius: 100,
                    margin: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setModal(!modal)}
                  alt=""
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {Object.keys(storeCHMImageOneData).length >= 1 &&
                  storeCHMImageOneData.statusCode === 1 && (
                    <img
                      src={
                        "data:image/jpeg;base64," +
                        storeCHMImageOneData.data[0].base64
                      }
                      style={{ width: isMobile() ? "200px" : '400px' }}
                      alt=""
                    />
                  )}

                {Object.keys(storeCHMImageTwoData).length >= 1 &&
                  storeCHMImageTwoData.statusCode === 1 && (
                    <img
                      src={
                        "data:image/jpeg;base64," +
                        storeCHMImageTwoData.data[0].base64
                      }
                      style={{ width:  isMobile() ? "200px" : '400px' }}
                      alt=""
                    />
                  )}

                {Object.keys(storeCHMImageThreeData).length >= 1 &&
                  storeCHMDIdData.data[0]?.damaged &&
                  storeCHMImageThreeData.statusCode === 1 && (
                    <img
                      src={
                        "data:image/jpeg;base64," +
                        storeCHMImageThreeData.data[0].base64
                      }
                      style={{ width:  isMobile() ? "200px" : '400px' }}
                      alt=""
                    />
                  )}

                {storeCHMImageOneData.statusCode === 0 && (
                  <p style={{ color: "white" }}> Image Not Found </p>
                )}
                {storeCHMImageTwoData.statusCode === 0 && (
                  <p style={{ color: "white" }}> Image Not Found </p>
                )}
                {storeCHMImageThreeData.statusCode === 0 && (
                  <p style={{ color: "white" }}> Image Not Found </p>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default CHMFormdetails;
