import { LoggedUserType } from "../models/GlobalModels";
import { API_RESPONSE_CODE, STORAGE_KEY } from "./Constant";

export const clearSession = () => {
  sessionStorage.clear(); //clear outlook detail
};

export const getFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? data : "";
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const saveInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const isAPIFailed = (fulfilled: any) => {
  if (
    fulfilled.responseInformation &&
    fulfilled.responseInformation &&
    fulfilled.responseInformation.responseCode === API_RESPONSE_CODE.FAIL
  ) {
    return true;
  } else {
    return false;
  }
};
export const isAPISuccess = (fulfilled: any) => {
  if (
    fulfilled.responseInformation &&
    fulfilled.responseInformation &&
    fulfilled.responseInformation.responseCode === API_RESPONSE_CODE.SUCCESS
  ) {
    return true;
  } else {
    return false;
  }
};

export const getUser = (): LoggedUserType => {
  let user: LoggedUserType = {
    candidateId: 0,
    candidateUniqueId: "",
    employeeUniqueId: "",
    firstname: "",
    lastName: "",
    userActivationStatus: 0,
    userActivationStatusName: "",
    accessToken: "",
    accessExpiryTime: "",
    resetToken: "",
    resetExpiryTime: "",
    mobileNumber: 0,
    emailId: "",
    loginType: 0,
    loginTypeName: "",
    roleId: -1,
  };
  const data = localStorage.getItem(STORAGE_KEY.AUTH_DATA);
  if (data) {
    user = JSON.parse(data);
  }
  return user;
};

export const regionContainingPoints = (points: any) => {
  let minLat: number, maxLat: number, minLng: number, maxLng: number;

  // Initialize first point
  ((point) => {
    minLat = point.centroid.lat;
    maxLat = point.centroid.lat;
    minLng = point.centroid.lng;
    maxLng = point.centroid.lng;
  })(points[0]);

  // Calculate rect points
  points.forEach((point: any) => {
    minLat = Math.min(minLat, point.centroid.lat);
    maxLat = Math.max(maxLat, point.centroid.lat);
    minLng = Math.min(minLng, point.centroid.lng);
    maxLng = Math.max(maxLng, point.centroid.lng);
  });

  const midLat = (minLat + maxLat) / 2;
  const midLng = (minLng + maxLng) / 2;
  // const deltaLat = maxLat - minLat;
  // const deltaLng = maxLng - minLng;

  return {
    lat: midLat,
    lng: midLng,
  };
};

export const dateConverter = (sdate: string, datetype : string) => {
  let fromDate = new Date(sdate);
  let toDate = new Date(sdate);
  let convertedDate;
    if(datetype === "fromDate"){
      convertedDate = fromDate.setDate(fromDate.getDate() - 1);
    }else{
      convertedDate = toDate.setDate(toDate.getDate() + 1);
    }

  var date = new Date(convertedDate);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  return `${date.getFullYear()}-${month}-${day}`;
};

export const isWithin30Meters = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  // Radius of earth in kilometers
  var R = 6371.0;

  // Convert degrees to radians
  var lat1Rad = toRadians(lat1);
  var lon1Rad = toRadians(lon1);
  var lat2Rad = toRadians(lat2);
  var lon2Rad = toRadians(lon2);

  // Differences
  var dlat = lat2Rad - lat1Rad;
  var dlon = lon2Rad - lon1Rad;

  // Haversine formula
  var a =
    Math.sin(dlat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  var distance = R * c;

  // Convert distance to meters and check if it's less than or equal to 30
  return distance * 1000 <= 30;
};

export const toRadians = (degrees: number) => {
  return degrees * (Math.PI / 180);
};
