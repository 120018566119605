import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN_STATUS, STORAGE_KEY } from "../../utils/Constant";
import { LoggedUserType } from "../../models/GlobalModels";
import { LoginInitialState } from "../../models/LoginModels";
import { clearSession, getFromLocalStorage, removeFromLocalStorage, saveInLocalStorage } from "../../utils/Util";
import { LoginV, logout, onPostSSOLogin, registeredEmp } from "../../services";

const blankUser: LoggedUserType = {
  candidateId: 0,
  candidateUniqueId: "",
  employeeUniqueId: "",
  firstname: "",
  lastName: "",
  userActivationStatus: 0,
  userActivationStatusName: "",
  accessToken: "",
  accessExpiryTime: "",
  resetToken: "",
  resetExpiryTime: "",
  mobileNumber: 0,
  emailId: "",
  loginType: 0,
  loginTypeName: "",
  roleId: -1
};
export const initialState: LoginInitialState = {
  status: LOGIN_STATUS.NO_AUTHENTICATED,
  user: blankUser,
};

export const getRegisteredEmployees = createAsyncThunk("dummyAPI", async () => {
  const res = await registeredEmp({});
  return res.data;
});
export const postSSOLogin = createAsyncThunk(
  "update/postLogin",
  async (data: any) => {
    const res = await onPostSSOLogin(data);
    return res.data;
  }
);
export const onLogout = createAsyncThunk("update/logout", async () => {
  const res = await logout();
  return res.data;
});
export const itusLogin = createAsyncThunk("itus/logiv", async (data: any) => {
  const res = await LoginV(data);
  return res.data;
});
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setAuthenticated: (state: LoginInitialState, action) => {
      state.status = action.payload.status;
      state.user = action.payload.user;
      saveInLocalStorage(
        STORAGE_KEY.AUTH_DATA,
        JSON.stringify(action.payload.user)
      );
    },
    setLocalLogout: (state:LoginInitialState, action) => {
      state.status = LOGIN_STATUS.NO_AUTHENTICATED;
      state.user = blankUser;
      removeFromLocalStorage(STORAGE_KEY.AUTH_DATA);
      removeFromLocalStorage(STORAGE_KEY.ITUS);
      clearSession();
    },
    checkAuthenticated: (state: LoginInitialState, action) => {
      const data = getFromLocalStorage(STORAGE_KEY.AUTH_DATA);
      if (data) {
        const sessionData: LoggedUserType = JSON.parse(data);
        if (sessionData && sessionData.accessToken) {
          state.status = LOGIN_STATUS.AUTHENTICATED;
          state.user = sessionData;
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRegisteredEmployees.fulfilled, (state: LoginInitialState, action) => {
        state.status = LOGIN_STATUS.AUTHENTICATED;
      })
      .addCase(itusLogin.fulfilled, (state: LoginInitialState, action) => {
        if (action && action.payload && action.payload.token) {
          saveInLocalStorage(STORAGE_KEY.ITUS, action.payload.token);
        }
      });
  },
});
export const loginSelector = (state: any) => state.login;
export const { setAuthenticated, setLocalLogout, checkAuthenticated } =
  loginSlice.actions;
export default loginSlice.reducer;
