import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { loginSelector } from "../redux/slices/loginSlice";
import { LOGIN_STATUS, STORAGE_KEY } from "../utils/Constant";
import { updateSelectedMenu } from "../redux/slices/dashboardSlice";
import { PrivateRoutes } from "./PrivateRoute";
import { PublicRoutes } from "./PublicRoutes";
import { useEffect, useState } from "react";

import { saveInLocalStorage } from "../utils/Util";

function AppRoute() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const loginStore = useSelector(loginSelector);

  const [token, setToken] = useState<string | null>(null);

  const href = window.location.href;
  let path: string = href.split("#")[1];
  if (path) {
    path = path.replace("/", "");
  }
  if (
    path &&
    !path.includes("login") &&
    loginStore.status === LOGIN_STATUS.AUTHENTICATED
  ) {
    dispatch(updateSelectedMenu(path));
  } else if (!path && loginStore.status === LOGIN_STATUS.AUTHENTICATED) {
    dispatch(updateSelectedMenu("home"));
  } else {
  }
  return (
    <Routes>
      <Route path="/*" element={<PrivateRoutes />} />
      {/* {loginStore.status === LOGIN_STATUS.AUTHENTICATED ? (
        <Route path="/*" element={<PrivateRoutes />} />
      ) : (
        <Route path="/*" element={<PublicRoutes />} />
      )} */}
    </Routes>
  );
}
export default AppRoute;
