import * as React from "react";
import { Label } from "reactstrap";
import "./FileInput.css";
import * as XLSX from "xlsx";

const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

const FileInput = ({ fileName, label, uploadFile }: any) => {
  const handleButtonClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = handleFileChange;
    input.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      uploadFile(file);
    }
  };

  const generateSampleXlFile = () => {
    const headersList = [
      "season",
      "applicationId",
      "state",
      "district",
      "tehsil",
      "block",
      "village",
      "crop name",
      "survey number",
      "farmer name",
      "farmer mobile number",
      "crop village code",
    ];
    let ws = XLSX.utils.json_to_sheet([], { header: headersList });
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Sample_Upload_Format.xlsx");
  };

  return (
    <div className="mb-2">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Label className="mb-1 text-start search-label text-muted">
          {label}
        </Label>
        {!isMobile() && (
          <Label
            className="mb-1 text-start search-label labelStyle"
            onClick={generateSampleXlFile}
          >
            Download sample Excel file to fill the Data
          </Label>
        )}
      </div>
      <div>
        {isMobile() ? (
          <div>
            <div className="filename-container">
              <span>
                {fileName.length > 25
                  ? fileName.slice(0, 25) + ". . . ."
                  : fileName}
              </span>
            </div>
            <div>
              <button
                className="UploadButton"
                style={{ height: 39 }}
                onClick={handleButtonClick}
              >
                Upload File
              </button>
            </div>
          </div>
        ) : (
          <div className="filename-container">
            <span>
              {fileName.length > 25
                ? fileName.slice(0, 25) + ". . . ."
                : fileName}
            </span>
            <button
              className="webButton"
              style={{ height: 40 }}
              onClick={handleButtonClick}
            >
              Upload File
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileInput;
