import { useEffect } from "react";

const LoadGoogleCharts = ({ onLoad }: any) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/charts/loader.js";
    script.async = true;
    script.onload = onLoad;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [onLoad]);

  return null;
};

export default LoadGoogleCharts;
