import { createSlice } from "@reduxjs/toolkit";

interface GlobalSliceProps {
  isLoading: boolean;
  error: boolean;
  drawerStatusStore: boolean;
  updatePageStore: boolean;
  storeCHMPaginationData: Object;
}

const initialState: GlobalSliceProps = {
  isLoading: false,
  error: false,
  drawerStatusStore: false,
  updatePageStore: false,
  storeCHMPaginationData: {
    offset: 0,
    limit: 25,
    selectedLimit: 25,
  },
};

export const globalSlice = createSlice({
  name: "globalDatum",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    handleDrawerStatusSlice(state, action) {
      state.drawerStatusStore = action.payload;
    },
    // Method to update page render
    handleUpdatePageSlice(state, action) {
      state.updatePageStore = action.payload;
    },

    handleCHMPaginationDataSlice(state, action) {
      state.storeCHMPaginationData = action.payload;
    },
  },
});

export const {
  startLoading,
  hasError,
  handleDrawerStatusSlice,
  handleUpdatePageSlice,
  handleCHMPaginationDataSlice,
} = globalSlice.actions;

export default globalSlice.reducer;
