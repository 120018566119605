/* eslint-disable no-lone-blocks */
import axios, {AxiosRequestConfig} from "axios";
import {paths} from "./ServiceConstants";
import {toast} from "react-toastify";

const ServicesManagerAPICall = {
    request: async (
        methodType: string,
        endpoint: string,
        data?: any,
        hasAuthToken: boolean = true,
        multipart: boolean = false,
        isGIS: boolean = false,
    ) => {
        let queryParams: string = "?";
        let url: any;
        let authenticationDatum: any;

        if (hasAuthToken) {
            authenticationDatum = "";
            //TODO
            // (await getStorageDatum(Constants.AUTHENTICATION_DATUM)) ?? {};
        }

        const config: AxiosRequestConfig = {
            method: "",
            url: "",
            headers: hasAuthToken
                ? {
                    // Authorization: `Bearer ${authenticationDatum[0].token ?? ""}`,
                    Authorization:
                        "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJnb3V0aGFtIiwiaWF0IjoxNzE5MjExODA5fQ.HcTqqzguypZoeX1omcx-fvvad3V-sAZ5f1LYqaYX0fc",
                    "Content-Type": multipart
                        ? "multipart/form-data"
                        : "application/json",
                }
                : {
                    Authorization: `Basic ${paths.basicAuthString}`,
                    "Content-Type": "application/json",
                },
        };

        try {
            switch (methodType) {
                case "GET": {
                    if (data) {
                        for (const key in data) {
                            queryParams += `${key}=${data[key]}&`;
                        }
                        url = isGIS ? `${paths.GIS_URL}${endpoint}${queryParams}` : `${paths.BASE_URL}${endpoint}${queryParams}`;
                    } else {
                        url = isGIS ? `${paths.GIS_URL}${endpoint}` : `${paths.BASE_URL}${endpoint}`;
                    }
                    config.method = `${methodType.toLowerCase()}`;
                    config.url = url;
                }
                    break;

                case "POST":
                case "PUT":
                case "PATCH": {
                    config.method = `${methodType.toLowerCase()}`;
                    config.url = isGIS ? `${paths.GIS_URL}${endpoint}` : `${paths.BASE_URL}${endpoint}`;
                    config.data = data;
                }
                    break;

                case "DELETE":
                case "OPTIONS": {
                    config.method = `${methodType.toLowerCase()}`;
                    config.url = isGIS ? `${paths.GIS_URL}${endpoint}` : `${paths.BASE_URL}${endpoint}`;
                }
                    break;

                default:
                    throw new Error(`Unsupported method type: ${methodType}`);
            }

            const response = await axios(config);
            if (response.status === 200) {
                return response.data ?? {};
            }
        } catch (error: any) {
            toast.error("Unable fetch the data, Please try again.");
            return error.message;
        }
    },
};

export default ServicesManagerAPICall;
