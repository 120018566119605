import { useState } from "react";
import { Label } from "reactstrap";
import Select from "react-select";
import {
  SelectOptionBooleanType,
  SelectOptionType,
} from "../models/GlobalModels";
import "./SearchBox.css";
import "./SelectBox.css";

interface ISelectBoxProps {
  options: any;
  label?: string;
  className?: string;
  value?: SelectOptionType | SelectOptionBooleanType;
  closeMenuOnSelect?: boolean;
  isMulti?: boolean;
  onChange?: (event: any) => void;
  customStyles?: any;
  placeholder?: string;
  disabled?: boolean;
}

const SelectBox: React.FunctionComponent<ISelectBoxProps> = ({
  options,
  label,
  className,
  value,
  closeMenuOnSelect,
  onChange,
  isMulti,
  customStyles,
  placeholder,
  disabled,
}) => {
  const [isClearable, setClearable] = useState<boolean>(false);
  return (
    <div className="mb-2">
      {label && (
        <Label className="mb-1 text-start search-label text-muted">
          {label}
        </Label>
      )}
      <div className={`close ${className}`}>
        <Select
          styles={customStyles}
          isMulti={isMulti ? isMulti : false}
          closeMenuOnSelect={!closeMenuOnSelect ? closeMenuOnSelect : true}
          value={value}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          isDisabled={disabled}
          onFocus={() => {
            setClearable(true);
          }}
          onBlur={() => {
            setClearable(false);
          }}
          isClearable={isClearable}
        />
      </div>
    </div>
  );
};

export default SelectBox;
