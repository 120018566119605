// Layout.js
import React from "react";
import { HashRouter } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";
import SideNav from "../components/SideNav";
import Main from "../routes/AppRoute";
import { loginSelector } from "../redux/slices/loginSlice";
import { dashboardSelector } from "../redux/slices/dashboardSlice";
import ToolBar from "../components/ToolBar";
import AppRoute from "../routes/AppRoute";

const Layout = () => {
  const loginStore = useSelector(loginSelector);
  const dashboardState = useSelector(dashboardSelector);
  return (
    <HashRouter>
      <div>
        {
          // loginStore.status === 'authenticated'
          //   ?
          <>
            {/* <SideNav /> */}
            <div
              className={classNames("content", {
                "is-open": dashboardState.isOpenSideMenu,
              })}
            >
              {/* <ToolBar /> */}
              <AppRoute />
            </div>
          </>
          // :<AppRoute />
        }
      </div>
    </HashRouter>
  );
};

export default Layout;
