import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polygon,
  LoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import { GOOGLE_MAP_KEY } from "../../../utils/Config";
import "../chmActivities/CHMActivities.css";
import { isWithin30Meters } from "../../../utils/Util";
import * as geolib from "geolib";

const mapContainerStyle = {
  width: "100%",
  height: "368px",
};

const defaultCords = {
  lat: 20.5937,
  lng: 78.9629,
};
const CHMActivityMap = ({ storeCHMDIdData }: any) => {
  const [cords, setCords] = useState<any>({});
  const [unqPolygon, setUnqPolygon] = useState<any>([]);
  const [infoWindow, setInfoWindow] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // After the map has been initialized...
    const style = document.createElement("style");
    style.textContent = `
  /* Your CSS rules */
  .gm-ui-hover-effect {
  opacity: 1;
  display: none !important;
}

.gm-style .gm-style-iw-d {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: none !important;
}

.gm-style .gm-style-iw-c {
  position: absolute !important;
  overflow: hidden !important;
  transform: translate3d(-50%, -100%, 0) !important;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3) !important;
  padding: 0px !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
  padding-top: 0px !important;
  margin: 0px !important;
  height: auto;
  width: auto;
}

.gm-style-iw-chr {
  display: none;
}
  /* More rules... */
`;
    document.head.append(style);

    setCords({
      lat: Number(
        storeCHMDIdData.data[0].polygonCentroid.split(" ")[1].replace(")", "")
      ),
      lng: Number(
        storeCHMDIdData.data[0].polygonCentroid
          .split(" ")[0]
          .replace("POINT(", "")
      ),
    });

    const polygon =
      storeCHMDIdData.data[0].polygon != null
        ? storeCHMDIdData.data[0].polygon
            .replace("POLYGON((", "")
            .replace("))", "")
            .split(",")
        : null;

    if (polygon != null) {
      let polygonCords = [];
      for (let index = 0; index < polygon.length; index++) {
        polygonCords.push({
          lat: Number(polygon[index].split(" ")[1]),
          lng: Number(polygon[index].split(" ")[0]),
        });
      }
      setUnqPolygon(polygonCords);
    }
  }, []);

  useEffect(() => {
    distanceCalculate();
  }, [unqPolygon, cords]);

  const distanceCalculate = () => {
    // Calculate the distance from the point to each vertex of the polygon
    const distances = unqPolygon?.map((vertex: any) =>
      geolib.getDistance(
        storeCHMDIdData.data[0].lat !== null ||
          storeCHMDIdData.data[0].lng !== null
          ? {
              lat: Number(
                storeCHMDIdData.data[0].lat
              ),
              lng: Number(
                storeCHMDIdData.data[0].lng
              ),
            }
          : defaultCords,
        vertex
      )
    );
    // The distance from the point to the polygon is the minimum of these distances
    const minDistance = Math.min(...distances);

    if (minDistance <= 30) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={cords.lat && cords.lng ? 16 : 4}
        center={cords.lat && cords.lng ? cords : defaultCords}
        options={{
          mapTypeId: "satellite",
          zoomControl: true, // disable zoom control button
          mapTypeControl: true, // disable map type control
          scaleControl: true, // disable scale control
          streetViewControl: false, // disable street view control
          rotateControl: false, // disable rotate control
          fullscreenControl: true, // disable fullscreen control
          gestureHandling: "panning", // disable all panning/zooming gestures
          //   disableDefaultUI: true, // disable all default UI
        }}
      >
        <>
          {Object.keys(cords).length > 0 && (
            <Marker
              key={`cordinates`}
              position={cords}
              icon={{
                url: isValid
                  ? require("../../../assets/img/marker.svg").default
                  : require("../../../assets/img/marker_red.svg").default,
                // scaledSize: new window.google.maps.Size(50, 50),
              }}
              onClick={() => {}}
              onMouseOver={() => {
                if (infoWindow === false) {
                  setInfoWindow(true);
                }
              }}
              onMouseOut={() => {
                if (infoWindow === true) {
                  setInfoWindow(false);
                }
              }}
            >
              {infoWindow && (
                <InfoWindow
                  position={cords}
                  onCloseClick={() => {
                    setInfoWindow(false);
                  }}
                >
                  <div
                    style={{
                      background: "#0D2316",
                      height: "87px",
                      width: "157px",
                      overflow: "none !important",
                      padding: 8,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <p style={{ color: "#FFFFFF", padding: 0, margin: 0 }}>
                      Survey Number :
                      <span style={{ fontWeight: 600 }}>
                        {storeCHMDIdData.data[0].surveyNumber}
                      </span>
                    </p>
                    <p style={{ color: "#FFFFFF", padding: 0, margin: 0 }}>
                      Crop :
                      <span style={{ fontWeight: 600 }}>
                        {storeCHMDIdData.data[0].cropNameAsPerApplication}
                      </span>
                    </p>
                    <p style={{ color: "#FFFFFF", padding: 0, margin: 0 }}>
                      Village :
                      <span style={{ fontWeight: 600 }}>
                        {" "}
                        {storeCHMDIdData.data[0].villageName}
                      </span>
                    </p>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          )}

          {Object.keys(unqPolygon).length > 0 && (
            <Polygon
              key={`polygon`}
              paths={unqPolygon}
              options={{
                fillColor: "#61322f",
                fillOpacity: 0.9,
                strokeColor: "black",
                // strokeOpacity: 0.8,
                strokeWeight: 1,
              }}
            />
          )}
        </>
      </GoogleMap>
    </LoadScript>
  );
};

export default CHMActivityMap;
