export const URL = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL || "",
  TASK_API_URL: process.env.REACT_APP_TASK_MANAGEMENT_API_URL || "",
  LEAD_API_URL: process.env.REACT_APP_LEAD_MANAGEMENT_API_URL || "",
  BASE_URL_IAGRI: process.env.REACT_APP_BASE_URL_KSHEMA_IAGRI || "",
  BASE_URL_KSHEMA: process.env.REACT_APP_BASE_URL_KSHEMA || "",
  BASE_URL_REST_FULL_ITUS: process.env.REACT_APP_BASE_URL_REST_FULL_ITUS || "",

  LOGIN_V: "loginv",
  PINCODE: "pincode",
  POST_SSO_VALIDATION: "authn/postSSOValidation",
  LOGOUT: "authn/logoutUser",
  LIST_OF_EMPLOYEES_STATUS: "api/getActiveEmployeesList",
};
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY || "";
export const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID || "";
