import {Buffer} from "buffer";

// entry path
//TODO
const BASE_PATH: string = "";

/* host names */
const UAT_HOST: string = "uat01.kshema.co";
const POC_HOST: string = "poc01.kshema.co";
const PROD_HOST: string = "prod01.omdc.co.in"

/* Change this to true to use uat */
const isFromUAT: boolean = true;

// const CURRENT_HOST: string = !isFromUAT ? UAT_HOST : POC_HOST;
const CURRENT_HOST: string = PROD_HOST;

// environment
const CURRENT_PATH: string = "https://" + CURRENT_HOST + "/" + BASE_PATH;
// GIS HOST
const GIS_PATH: string = "https://prod01.kshema.co/";

// types of methods
export const method: {
  DELETE: string;
  POST: string;
  GET: string;
  PUT: string;
  PATCH: string;
  OPTIONS: string;
} = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
  OPTIONS: "OPTIONS",
};

//TODO
const credentials = {
  Username: "",
  Password: "i luv Kshema",
};

// api paths
export const paths = {
  // base URL
  BASE_URL: CURRENT_PATH,
  GIS_URL: GIS_PATH,

  // Encode credentials in Base64
  basicAuthString: Buffer.from(
    `${credentials.Username}:${credentials.Password}`
  ).toString("base64"),

  AUTHENTICATION_ENDPOINT: "REST/authentication",
  CHM_STATES_DATA: "chmops/chmOps/getAllStates",
  CHM_DISTRICTS_DATA: "chmops/chmOps/getAllDistricts",
  CHM_LEVEL4_DATA: "chmops/chmOps/getLevel4Data",
  CHM_LEVEL5_DATA: "chmops/chmOps/getLevel5Data",
  CHM_VILLAGES_DATA: "chmops/chmOps/getVillageData",
  CHM_SEASON_DATA: "chmops/chmOps/getSeasonsData",
  CHM_CROPS_DATA: "chmops/chmOps/getCropData",
  CHM_GET_CHM_INFO: "chmops/chmOps/getChmInfo",
  CHM_GET_CHM_INFO_BY_ID: "chmops/chmOps/getChmFormData",
  GIS_COMPUTEINDEX: "gischm/computeindex",
  GIS_CHM_CHART: "gischm/fetchdata",
  CHM_IMAGES: "chmops/chmOps/getImages",
  CHM_VIEWMAP_POLYGON_VALUES: "chmops/chmOps/viewMapData",
  CHM_EXCEL_DOWNLOAD_DATA: "chmops/chmOps/excelDownload",
  CHM_EXCEL_SHEET_UPLOAD: "chmops/chmOps/importExcel",
  CHM_SURVEYOR_DATA: "chmops/chmOps/getSurveyorData",
  CHM_ASSIGNE_SURVEYER: "chmops/chmOps/assignSurveyor",
  CHM_ERORLOGS: "chmops/chmOps/getErrorLogs",
};

export const METHOD = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
};
