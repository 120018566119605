import { useDispatch } from "react-redux";
import { handleUpdatePageSlice } from "../redux/slices/globalSlice";
import {
  handleCHMGisChartDataSlice,
  handleCHMInfoByIdDataSlice,
} from "../redux/slices/chmActivitySlice";

const Label = ({ name, arrow }: any) => {
  const dispatch: any = useDispatch();
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#F4F6F8",
        border: "1px solid #D2D2D2",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        padding: "4px",
        fontWeight: 600,
        marginBottom: 15,
      }}
    >
      {arrow && (
        <img
          onClick={() => {
            dispatch(handleUpdatePageSlice(false));
            dispatch(handleCHMInfoByIdDataSlice({}));
            dispatch(handleCHMGisChartDataSlice({}));
          }}
          src={require("../assets/img/back-svgrepo-com.svg").default}
          style={{ width: 12, marginRight: 5, cursor: "pointer" }}
        />
      )}
      {name}
    </div>
  );
};

export default Label;
