import { Container, Row, Col, Input } from "reactstrap";
import SearchBox from "./SearchBox";
import "./Globals.css";
import { wrap } from "module";

/**Label and input props for IEC form details  */
interface IinputProps {
  placeHolder: string;
  label?: string;
  icon?: React.ReactElement;
  value?: string | number;
  onChange?: (e: any) => void;
  numberOnly?: boolean;
  fixedLabel: string;
}

/**LabelINput Component */
export const LabelInput = (props: IinputProps) => {
  return (
    <div>
      <Container>
        <Row style={{ marginBottom: "4px" }}>
          <Col
            className="bg-light border-bottom alignLabel"
            style={{
              fontSize: 14,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingTop : 10,
              paddingBottom : 10
            }}
          >
            {props.fixedLabel}
          </Col>
          
          <Col
            className="bg-light border-bottom alignLabel"
            style={{
              fontSize: 14,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingTop : 10,
              paddingBottom : 10
            }}
          >
            {props.placeHolder}
          </Col>
          
        </Row>
      </Container>
    </div>
  );
};
