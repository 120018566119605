import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import LoadGoogleCharts from "./LoadGoogleCharts"; // Adjust the import path as necessary

const Charts = ({ storeCHMChartData }: any) => {
  const [chartData, setChartData] = useState([
    ["Date", "NDVI", "NDMI", "Cloud"],
  ]);
  const [isGoogleChartsLoaded, setIsGoogleChartsLoaded] = useState(false);

  useEffect(() => {
    if (storeCHMChartData && storeCHMChartData.data) {
      const dates = storeCHMChartData.data.dates
        .replace(/[\[\]"]/g, "")
        .split(",")
        .map((date: any) => date.trim().replace(/'/g, ""));

      const ndviValues = storeCHMChartData.data?.NDVI?.replace(/[\[\]"]/g, "")
        ?.split(",")
        ?.map((ndvi: any) => Number(ndvi));


      const ndmiValues = storeCHMChartData.data?.NDMI?.replace(/[\[\]"]/g, "")
        ?.split(",")
        ?.map((ndmi: any) => Number(ndmi));


      const cloudValues = storeCHMChartData?.data?.cloud
        ?.replace(/[\[\]"]/g, "")
        ?.split(",")
        ?.map((cloud: any) => Number(cloud));


      if (ndmiValues === undefined) {
        const updatedData = [["Date", "NDVI", "Cloud"]];
        dates.forEach((date: any, index: any) => {
          updatedData.push([date, ndviValues[index], cloudValues[index]]);
        });

        setChartData(updatedData);
      } else {
        const updatedData = [["Date", "NDVI", "NDMI", "Cloud"]];
        dates.forEach((date: any, index: any) => {
          updatedData.push([
            date,
            ndviValues[index],
            ndmiValues[index],
            cloudValues[index],
          ]);
        });

        setChartData(updatedData);
      }
    }
  }, [storeCHMChartData]);

  const options = {
    title: "Time Series of NDVI/NDMI and Cloud",
    hAxis: { title: "Date" },
    vAxis: { title: "NDVI" },
    series: {
      0: { type: "line", targetAxisIndex: 0 },
      1: { type: "line", targetAxisIndex: 0 },
      2: { type: "line", targetAxisIndex: 0 },
      // 2: { type: "scatter", targetAxisIndex: 1, pointSize: 5 },
    },
    vAxes: {
      0: { title: "NDVI/NDMI/Cloud" },
      1: { title: "Cloud" },
    },
    colors: ["#537C5B", "#000000", "#829ED1"],
    animation: {
      startup: true,
      easing: "linear",
      duration: 1500,
    },
  };

  return (
    <div>
      {!isGoogleChartsLoaded && (
        <LoadGoogleCharts onLoad={() => setIsGoogleChartsLoaded(true)} />
      )}
      {isGoogleChartsLoaded && chartData.length > 1 ? (
        <Chart
          chartType="ComboChart"
          data={chartData}
          options={options}
          width="100%"
          height="500px"
        />
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
};

export default Charts;
